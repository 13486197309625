import { annotate } from "rough-notation";

const highlights = [];

const removeHighlights = () => {
  highlights.forEach((highlight) => {
    highlight.hide();
    highlight.remove();
  });
};

export const highlight = (elements) => {
  if (!elements.length) return;

  removeHighlights();

  const options = {
    type: "highlight",
    color: "#FFE945",
    animationDuration: 400,
  };

  elements.forEach((el) => {
    const highlight = annotate(el, options);

    highlights.push(highlight);

    highlight.show();
  });
};

export default {
  highlight,
};
