import { highlight } from "~/resources/textHighlighter";

export default {
  data() {
    return {
      highlightTimeout: null,
    };
  },
  watch: {
    $route() {
      this.triggerTextHighlights();
    },
  },
  mounted() {
    this.triggerTextHighlights();
  },
  methods: {
    triggerTextHighlights() {
      if (this.highlightTimeout) clearTimeout(this.highlightTimeout);

      this.highlightTimeout = setTimeout(() => {
        highlight(this.$el.querySelectorAll("mark"));
      }, 1500);
    },
  },
  beforeUnmount() {
    if (this.highlightTimeout) clearTimeout(this.highlightTimeout);
  },
};
